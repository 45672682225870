import React from "react";
import PreislandingLayout, {
  ProductListContext,
} from "../../layouts/PreislandingLayout";
import ProductsTable from "../../components/products-table/ProductsTable";
import PriceComparisonBanner from "../../components/price-comparison-banner/PriceComparisonBanner";
import PriceFAQ from "../../components/price-faq/PriceFAQ";
import SEOComponent from "../../components/Shared/Seo";

const ZinkpreisPage = () => {
  const { Consumer } = ProductListContext;
  return (
    <PreislandingLayout>
      <SEOComponent page="zinkpreis" />
      <Consumer>
        {value => (
          <ProductsTable
            productList={value.productList}
            categoryId={93}
            name="Zinkpreis"
          />
        )}
      </Consumer>
      <PriceComparisonBanner />
      <PriceFAQ page="zinkpreis" />
    </PreislandingLayout>
  );
};

export default ZinkpreisPage;
